@import "@/core/styles/colors.scss";
@import "@/shared/styles/mixins.scss";

.account {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  width: 100%;

  @include media-breakpoint-lg {
    margin-bottom: 30px;
  }

  &__thumbnail {
    width: 50px;
    min-width: 50px;
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    background: $stroke-color-2;
    border-radius: 50%;
    margin-right: 20px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 50%;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 50%;
      padding: 2px;
      background: $main-color-1;
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      background-image: url('../../../resources/images/account-diamond.png'),
        linear-gradient(90deg, #6bbaed 0%, #9fa3e7 40.63%, #c195e3 73.96%, #dd88e0 100%);
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
      right: -8px;
      bottom: -8px;

      @include media-breakpoint-xxxl {
        transform: scale(0.8);
      }
    }
  }

  &__dropdown {
    background: $color-bg;
    border: 1px solid #3e3e3e;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    width: 190px;
    margin-top: 15px;
    padding: 5px 0;
    font-size: 14px;
    color: $white;

    &_item {
      color: $white;
      font-weight: 500;
      transition: 0.3s all;

      &:last-child {
        color: $alert-color;
      }

      &:hover {
        opacity: 0.8;
      }

      &_icon {
        width: 20px;
        height: 20px;
        margin-right: 12px;

        @include media-breakpoint-xxxl {
          margin-right: 8px;
        }
      }
    }
  }

  &__username {
    width: auto;
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
    color: #fdfdfd;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}