@import '@/shared/styles/mixins.scss';
@import "@/core/styles/colors.scss";

.header {
  background: rgba(5, 18, 27, 0.6);
  backdrop-filter: blur(20px);
  position: fixed;
  width: 100%;
  z-index: 999;

  &__height {
    height: 90px;

    @include media-breakpoint-xxxl {
      height: 80px;
    }
  }

  &__link {
    color: $white;
    font-family: $primary-font;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    transition: 0.2s all;

    &:hover {
      color: $primary;
    }
  }

  &__inner {
    height: 90px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

    @include media-breakpoint-xxxl {
      height: 80px;
    }

    &_left {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &_logo {
      width: 15%;
      margin-right: 3%;
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    &_nav {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      @include media-breakpoint-lg {
        display: none;
      }

      &__link {
        color: white;
        font-weight: 500;
        display: flex;
        align-items: center;
        white-space: nowrap;

        @include media-breakpoint-xxxl {
          font-size: 1rem;
        }
      }
    }

    &_signIn {
      @include media-breakpoint-lg {
        display: none;
      }
    }

    &_notifications {
      margin-right: 1.5%;

      @include media-breakpoint-lg {
        margin-right: 20px;
      }
    }

    &_account {
      margin-left: 1.5%;
      @include media-breakpoint-lg {
        display: none;
      }
    }

    &_mobile {
      &_icon {
        display: none;
        cursor: pointer;

        @include media-breakpoint-lg {
          display: block;
          width: 32px;
          height: 32px;
          margin-left: auto;
          background-image: url(../../../resources/icons/mobile-menu-icon.png);
        }
      }

      &_search {
        display: none;

        &>svg {
          path {
            fill: #01e0ed;
          }
        }

        @include media-breakpoint-lg {
          display: block;
          flex: 1;

          &>svg {
            display: block;
            width: 50px;
            height: 50px;
            border: 1px solid rgba(253, 253, 253, 0.05);
            border-radius: 50%;
            padding: 15px;
            margin-left: auto;
            margin-right: 25px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
