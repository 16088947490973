@import "@/core/styles/colors.scss";

.roles {
  &__label {
    display: block;
    font-size: 10px;
    margin-left: 2px;
    margin-bottom: 8px;
  }

  &__error {
    position: absolute;
    bottom: -14px;
    right: 0;
    width: 100%;
    max-width: 520px;
    color: tomato;
    font-size: 10px;
  }

  &__select {
    display: block;
    max-width: 520px;
    margin: 0 auto 25px
  }

  &__search {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__wrapper {
      display: block;
    }

    &__submit {
      margin-left: 10px;

      &[disabled] {
        filter: grayscale(90%);
      }
    }
  }

  &__list {
    margin-bottom: 10px;

    &_item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 12px;
      margin-bottom: 10px;

      &__remove {
        display: inline-block;
        vertical-align: top;
        width: 16px;
        height: 16px;
        padding: 2px;
        border-radius: 0;
        transition: background-color .4s;

        &:hover {
          background-color: $bg-low-opacity-2;
        }

        &_icon {
          display: inline-block;
          vertical-align: top;
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  &__highlight {
    font-size: 16px;
    color: $white;
  }
}