.error {
    max-width: 330px;
    padding: 30px 20px;
    margin: 40px auto 80px;
    text-align: center;
    color: tomato;
    background-color: #211313;
    border-radius: 4px;

    &__title {
        font-size: 20px;
        margin-bottom: .5em;
    }

    &__descripton {
        font-size: 18px;
    }
}