@import '../../styles/colors.scss';

.mobile_menu {
  width: 100vw;
  height: 100vh;
  padding: 25px 40px;
  background: $color-bg;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__menu {
      display: flex;
      align-items: center;

      svg {
        display: block;
        width: 24px;
        height: 24px;
        margin-left: 16px;
      }

      span {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        background: linear-gradient(270deg, #01e0ed 0%, #0165a7 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    &__close {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  &__navs {
    margin-top: 80px;
    margin-bottom: 50px;
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  &__nav {
    background: $color-bg;
    color: #fff;

    &:first-of-type {
      margin-top: -25px !important;
    }

    &__section {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin: 25px 0;
      justify-content: flex-start;
      border-left: none !important;

      &>div {
        flex-grow: inherit !important;
      }

      &__link {
        color: #fff;
        display: block;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        padding: 7px 10px;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }

    &__arrow {
      width: 15px;
      height: 9px;
      margin-left: 10px;
      margin-right: 10px;
    }

    &__link {
      font-weight: 700;
      font-size: 18px;
      color: #fff;
      display: block;
      margin-bottom: 50px;
      padding-left: 16px;
    }
  }
}

.selected {
  border-left: 2px solid #00c2ff;
  color: #00c2ff;

  &[class~='Mui-expanded'] {
    color: #00c2ff;
  }

  svg {
    path {
      fill: #00c2ff;
    }
  }
}
