@import "@/core/styles/colors.scss";

.uploader {
  &__label {
    display: block;
    font-size: 10px;
    margin-left: 2px;
    margin-bottom: 8px;
  }

  &__error {
    position: absolute;
    bottom: -14px;
    right: 0;
    width: 100%;
    max-width: 520px;
    color: tomato;
    font-size: 10px;
  }

  &__row {
    &_dashed {
      margin: 0 auto 16px;
      border: 1px dashed #3e3e3e;
      padding: 10px;
    }
  }

  &__image {
    max-height: 200px;
    max-width: 100%;
    display: flex;
    margin-top: 15px;

    &:not([src]) {
      display: none;
    }
  }
}
