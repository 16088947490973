@import '@/shared/styles/mixins.scss';

.viewer {
  flex: 1;
  display: flex;
  height: inherit;

  model-viewer {
    width: 100%;
    height: 100%;
    min-height: 350px;
    background-color: transparent;
    --poster-color: transparent;
  }

  &__poster {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    img {
      display: block;
      width: 150px;
    }
  }
}
