@import '@/shared/styles/mixins';

.searchUser {
  display: block;
  width: 100%;

  &__popper {
    z-index: 3;
    max-width: calc(100% - 78px);
    width: 100%;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    width: 100%;
    margin-top: 5px;
    background: $color-bg;
    border: 1px solid $stroke-color-2;
    box-shadow: 0 6px 50px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    overflow: hidden;
    padding: 12px 0;
    overflow-y: auto;

    &__user {
      display: flex;
      align-items: center;
      border: 1px solid transparent;
      border-radius: 2px;
      padding: 5px 14px;
      color: white;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background: $bg-low-opacity-1;
        cursor: pointer;
      }
    }
  }
}