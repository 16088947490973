@import '@/core/styles/colors.scss';

.edit {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
    color: $white;

    &__title {
        font-size: 32px;
        font-family: $primary-font;
        margin-bottom: .8em;
        text-transform: uppercase;
    }

    &__form {
        margin-bottom: 15px;
    }

    &__row {
        position: relative;
        display: block;
        text-align: left;
        max-width: 520px;
        margin: 0 auto 25px;

        &>label {
            display: block;
            font-size: 10px;
            margin-bottom: 6px;
        }
    }

    &__field {
        width: 100%;
        font-weight: 400;
        font-family: $primary-font;
        color: $text-color-1;
        background: $stroke-color-2;
        border: 1px solid $color-bg-1;
        border-radius: 8px;
        padding: 12px 12px;

        &:hover {
            background: $stroke-color-2;
            border-color: $bg-low-opacity-2;
        }

        &:focus {
            border-color: $stroke-color-2;
        }
    }

    &__btn {
        width: 100%;
        line-height: 50px;
        height: 50px;
        max-width: 520px;
        margin-top: 30px;
        font-size: 18px;
        border-radius: 50px;

        &::before,
        &::after {
            border-radius: 50px;
        }

        &:hover {
            background: rgba($primary, .6);
            color: $text-color-1;
        }
    }

    &__link {
        display: inline-block;
        height: 36px;
        line-height: 36px;
        width: 100%;
        max-width: 520px;
        color: $white;
        transition: .2s color;
        margin: 0 auto;

        &:hover {
            color: $primary;
        }
    }

    &__error {
        position: absolute;
        bottom: -14px;
        right: 0;
        width: 100%;
        max-width: 520px;
        color: tomato;
        font-size: 10px;
    }

    &__notEditable {
        width: 100%;
        max-width: 520px;
        padding: 5px 0;
        margin: 30px auto;
        text-align: center;
        color: tomato;
        background-color: #211313;
        border-radius: 4px;
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        border: 1px solid #3e3e3e;
        border-radius: 4px;
        padding: 6px 0px;
        background-color: #04131d;
        font-size: 12px;
        max-height: 80px;
        overflow: auto;
        z-index: 1;

        &:empty {
            display: none;
        }

        div {
            cursor: pointer;
            text-align: start;
            padding: 4px 12px;
            font-size: 12px;

            &:hover {
                background-color: $stroke-color-2;
            }
        }
    }

    [class~='MuiTextField-root'],
    // [class~='MuiButtonBase-root'],
    [class~='MuiOutlinedInpu'],
    [class~='MuiInputBase-root'] {
        width: 100%;
        border: none !important;
        border-radius: 8px;
        color: $text-color-1;
    }

    [class~='MuiOutlinedInput-notchedOutline'] {
        border: none;
    }

    [class~='MuiCalendarPicker-root'] {
        color: #fff;
    }

    [class~='MuiInputBase-input'] {
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        font-family: $primary-font;
        color: $text-color-1;
        background: $stroke-color-2;
        border: none;
        border-radius: 8px;
        padding: 10px 12px;
        width: 100%;
        font-weight: 400;
        font-family: $primary-font;
        color: $text-color-1;
        background: $stroke-color-2;
        border-radius: 8px;
        padding: 12px;
    }

    [class~='MuiPickersDay-root'] {
        &:hover {
            background-color: #828082 !important;
            color: $white;
        }
    }

    [class~='MuiSvgIcon-root'] {
        fill: #fff;
    }

    [class~='Mui-selected'] {
        background-color: $color-bg;
    }

    [class~='MuiTypography-root'] {
        color: #fff;
    }
}

[class~='MuiClockPicker-root'] {
    background-color: $bg-low-opacity-2 !important;
    color: #fff;

    span {
        color: #fff;
    }

    // [class~='MuiButtonBase-root'] {
    //     color: text-color-3;

    //     &:hover {
    //         background-color: #333;
    //     }
    // }
}

[class~='MuiList-root'] {
    [class~='MuiMenuItem-root'] {
        cursor: pointer;
        text-align: start;
        padding: 4px 12px;
        font-size: 12px;

        &:hover {
            background-color: $stroke-color-2;
        }
    }

    [class~='Mui-selected'] {
        background-color: $color-bg;
    }
}

[class~='MuiCalendarPicker-root'] {
    color: #fff;

    // [class~='MuiTypography-root'] {
    //     color: #fff;
    // }

    // [class~='MuiButtonBase-root'] {
    //     color: #fff;
    // }

    [class~='MuiPickersDay-root'] {
        color: $text-color-2;

        &:hover {
            background-color: #333;
            color: $white;
        }
    }
}