@import '@/shared/styles/mixins';

[class~='MuiDrawer-root'] {
  .sidebar {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(30px);
    position: fixed;
    top: 70px;
    color: white;
    padding-top: 30px;
    width: 90px;
    height: calc(100% - 70px);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow-x: hidden;
    z-index: 990;

    @include media-breakpoint-xxxl {
      top: 80px;
    }

    &__handler {
      position: fixed;
      left: 15px;
      bottom: 31px;
      width: 56px;
      height: 56px;
      z-index: 1000;
      background-image: $main-color-1;
      cursor: pointer;
      display: none;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      @include media-breakpoint-md {
        display: flex;
      }

      svg {
        width: 20px;
      }
    }

    @include media-breakpoint-md {
      width: 0;
    }

    &.open {
      width: 250px;

      & .sidebar {
        &__list {
          &__item {
            &__button {
              gap: 15px;
              padding: 20px 35px 20px 20px;

              &_text {
                opacity: 1;
              }

              &_plus {
                width: 24px;
                height: 24px;

                svg {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }
      }
    }

    &__divider {
      border-color: rgba(253, 253, 253, 0.1);
      margin: 20px 35px 20px 0;
    }

    &__list {
      padding: 0;

      &__item {
        &__button {
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 20px 35px 20px 15px;

          &:before {
            content: '';
            width: 2px;
            height: 24px;
            position: absolute;
            background: transparent;
            left: 0;
          }

          &_icon {
            min-width: unset;

            svg {
              width: 20px;
              height: 20px;
              fill: #FFFFFF;

              * {
                stroke: white;
              }
            }

            &_logout {
              svg * {
                fill: transparent;
                stroke: #ff4443;
              }
            }

            &_active svg * {
              stroke: $main-color-2;
            }
          }

          &_text {
            margin: 0;
            flex: unset;
            white-space: nowrap;
            opacity: 0;

            &_logout {
              color: #ff4443;
            }
          }

          &:hover,
          &_active {
            color: $main-color-2;

            &:before {
              background: $main-color-2;
            }
          }

          &:hover &_icon svg * {
            stroke: $main-color-2;
          }

          &:hover &_icon_logout svg * {
            stroke: #ff4443;
          }

          &_plus {
            min-width: unset;
            width: 18px;
            height: 18px;
            background-image: $main-color-1;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }
  }
}
