$primary: #01e0ed;

$text-color-1: #ffffff;
$text-color-2: #3b393c;
$text-color-3: #828082;

$stroke-color-1: #d7d7d7;
$strokeColor2: #3e3e3e;
$stroke-color-2: #3e3e3e;
$white: #fdfdfd;
$n: transparent;

$main-color-1: linear-gradient(270deg, #01e0ed 0%, #0165a7 100%);
$main-color-2: #01e0ed;
$color-bg: #181818;
$color-bg-1: #04131d;
$bg-low-opacity-1: #fdfdfd0d;
$bg-low-opacity-2: #fdfdfd1a;

$alert-color: #eb5757;
$bg-el-svg: linear-gradient(270deg, #12165c 0%, #05141d 100%);

$primary-font: 'Montserrat', Roboto, sans-serif;
$secondary-font: 'DM Sans', sans-serif;

$sm: 428px;
$md: 767.98px;
$lg: 991.98px;
$xl: 1199.98px;
$xxl: 1440px;
$xxxl: 1660px;