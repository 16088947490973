@import '@/core/styles/colors.scss';

.walletPopup {
  width: 800px;
  margin: 20px auto 0;

  &__description {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    font-size: 18px;
    text-align: center;

    span {
      color: $alert-color;
    }
  }

  &__cards {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  &__card {
    background: rgba(253, 253, 253, 0.05);
    border-radius: 10px;
    padding: 30px;
    cursor: pointer;

    &__inner {
      border-radius: 50%;
      padding: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(253, 253, 253, 0.05);

      &__img {
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #04131d;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
      }
    }
  }
}
