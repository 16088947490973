@import '@/shared/styles/mixins';

.footer {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
  padding: 40px 0;
  margin-top: 130px;

  @include media-breakpoint-md {
    margin-top: 90px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    &__logo {
      cursor: pointer;
    }
  }

  &__line {
    height: 2px;
    width: 100%;
    margin-top: 20px;

    &_top {
      background-image: $main-color-1;
    }

    &_bottom {
      background-image: linear-gradient(269.59deg, #0165a7 -12.09%, #01e0ed 97.7%);
    }
  }

  &__copyrights {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: white;
    margin-top: 20px;
  }
}