@import '@/core/styles/colors.scss';
@import '@/shared/styles/mixins.scss';

.table {
    &__search_wrap {
        display: flex;
        gap: 25px;
    }

    &__tabs {
        border-bottom: 1px solid $text-color-2;
    }

    &__tab {
        display: inline-block;
        vertical-align: top;
        padding: 12px 25px;
        color: $white;
        cursor: pointer;

        &__active {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                height: 2px;
                background-image: linear-gradient(270deg, #01e0ed 0%, #0165a7 100%);
            }
        }
    }

    &__link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 6px 24px;
        height: 50px;
        border-radius: 35px;
        font-family: $primary-font;
        text-transform: none;
        transition: 0.5s all;
        position: relative;
        color: white;
        margin-left: auto;
        background-size: 300% 100%;
        background-image: linear-gradient(to right, #0165a7, #01cad5, #01cad5, #0165a7);
        box-shadow: 0 2px 15px rgb(0 135 211 / 40%);
        z-index: auto;
        overflow: hidden;

        &:hover {
            background-position: 100% 0%;
        }

        &::before {
            padding: 0px;
            border-radius: 0px;
        }

        @include media-breakpoint-lg {
            margin-top: 20px;
            width: 60%;
        }

        @include media-breakpoint-md {
            margin: 20px auto 0;
            width: 60%;
        }
    }

    &__errorloading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--table-height);
    }

    // [class~='MuiSvgIcon-root'] {
    //     fill: $white;
    // }

    [class~='MuiTextField-root'] {
        height: 50px;
        border-radius: 30px;
    }

    [class~='MuiOutlinedInput-notchedOutline'] {
        height: 55px;
        border-color: $stroke-color-2 !important;
    }

    [class~='MuiOutlinedInput-root'] {
        height: 50px;
    }

    [class~='MuiInputLabel-outlined'] {
        color: $text-color-3;
    }

    [class~='MuiSelect-select'] {
        color: $white;
        font-weight: 500;
        font-size: 14px;
        background: rgba(5, 18, 27);
        font-family: $primary-font;
        min-width: 200px;
        text-align: left;
    }

    #select-search-type {
        padding: 0;
    }
}
