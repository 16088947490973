@import '@/core/styles/colors.scss';

//Media breakpoint
@mixin media-breakpoint-sm {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin media-breakpoint-md {
  @media (max-width: $md) {
    @content;
  }
}

@mixin media-breakpoint-lg {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin media-breakpoint-xl {
  @media (max-width: $xl) {
    @content;
  }
}

@mixin media-breakpoint-xxl {
  @media (max-width: $xxl) {
    @content;
  }
}

@mixin media-breakpoint-xxxl {
  @media (max-width: $xxxl) {
    @content;
  }
}

@mixin font-universal($font: 'Montserrat',
  $family: sans-serif,
  $font-size: 14px,
  $font-weight: 400,
  $line-height: 1,
  $letter-spacing: 0em,
  $text-align: left,
  $text-color: white) {
  font-family: $font, $family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  text-align: $text-align;
  color: $text-color;
}

@mixin grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;

  @include media-breakpoint-md {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@mixin title-32-breakpoints {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;

  @media (max-width: 1919px) {
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
  }
}