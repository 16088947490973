@import "@/core/styles/colors.scss";

.select {

  &__label {
    display: block;
    font-size: 10px;
    margin-left: 2px;
    margin-bottom: 8px;
  }

  &__error {
    position: absolute;
    bottom: -14px;
    right: 0;
    width: 100%;
    max-width: 520px;
    color: tomato;
    font-size: 10px;
  }

  [class~='MuiSelect-select'] {
    &[class~='Mui-disabled'] {
      -webkit-text-fill-color: rgba(255, 255, 255, 0.75);
    }
  }
}

.menuPaper {
  max-height: 180px;
  max-width: 520px;
}
