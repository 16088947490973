@import '@/core/styles/colors.scss';

[class~='MuiButtonBase-root'] {
    color: $white;
    fill: $white;
    line-height: 1.2 !important;

    &:hover {
        color: $text-color-3;
        fill: $text-color-3;
    }

    .tableActions {
        &__link {
            display: flex;
            align-items: center;
            width: 100%;
            color: $white;
            fill: $white;

            &:hover {
                color: $text-color-3;
                fill: $text-color-3;
            }
        }

        &__icon {
            display: inline-block;
            vertical-align: top;
            height: 18px;
            margin-right: 4px;

            &_btn {
                display: inline-block;
                vertical-align: top;
                fill: $white;
                cursor: pointer;
            }
        }
    }
}

.tableActions {
    &__modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px 60px;
        text-align: center;
        background-color: $color-bg-1;
        border-radius: 10px;

        .tableActions {
            &__text {
                margin-bottom: 20px;
            }

            &__btn {
                margin: 0 10px;
            }
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.paper {
    max-height: 200px;
}