.formControl {
  width: 100%;
}

.indeterminateColor {
  color: #f50057;
}

.selectAllText {
  font-weight: 500;
}

.selectedAll {

  background-color: rgba(0, 0, 0, 0.08);

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.select {
  &__label {
    display: block;
    font-size: 10px;
    margin-left: 2px;
    margin-bottom: 8px;
  }

  &__error {
    position: absolute;
    bottom: -14px;
    right: 0;
    width: 100%;
    max-width: 520px;
    color: tomato;
    font-size: 10px;
  }

  &__item span {
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    font-family: "Montserrat", Roboto, sans-serif;
    color: #ffffff;
  }

  &__dropdown {
    height: 30px;

    span {
      font-size: 12px;
    }
  }
}

.input {
  &__error {
    position: absolute;
    bottom: -14px;
    right: 0;
    width: 100%;
    max-width: 520px;
    color: tomato;
    font-size: 10px;
  }
}