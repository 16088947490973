@import '../../styles/mixins';

.modal {
  max-width: 864px;
  width: 100%;
  box-shadow: 0px -6px 30px rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 50px 0 70px;
  margin: 0 16px;
  position: relative;
  color: white;
  max-height: 90vh;
  overflow-y: auto;

  &__light {
    background: #051D2D;
    border-radius: 16px;
  }

  &__dark {
    background: $color-bg;
  }

  @include media-breakpoint-md {
    background: #051D2D;
    border-radius: 16px;
    padding: 50px 0 40px;
  }

  &__root {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    text-align: center;
    padding: 0 30px;

    &_normal {
      font-weight: normal;
    }

    &_bold {
      font-weight: 700;
    }

    &_underline {
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(215, 215, 215, 0.4);
    }
    @include title-32-breakpoints;
  }

  &__close {
    position: absolute;
    top: 22px;
    right: 22px;
    width: auto !important;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
