@import './fonts.scss';
@import './colors.scss';
@import '../../shared/components/Notify/react-toastify.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $stroke-color-2;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(269.59deg, #01e0ed -12.09%, #0165a7 97.7%);
    border-radius: 5px;
  }
}

a {
  text-decoration: none;
}

h1 {
  margin-bottom: 0.8em;
}

body {
  background: $color-bg;
  display: flex;
  min-height: 100vh;
  color: $text-color-1;

  >div#root {
    flex: 1 1 auto;
    max-width: -webkit-fill-available;
    max-width: -moz-available;
  }
}

.swiper {
  max-width: 90vw;
  cursor: pointer;
}