@import '@/core/styles/colors.scss';

:root {
  --table-height: 578px;
}

.negative {
  color: $alert-color;
}

.neutral {
  color: $text-color-3;
}

.tableBody {
  width: 100%;
  height: var(--table-height);
  margin-bottom: 24px;
  border: none;

  svg {
    color: #FFFFFF;
  }

  &__errorloading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--table-height);
  }

  [class~='MuiDataGrid-root'] {
    width: 100%;
    height: var(--table-height);
    margin-bottom: 24px;
    border: none;

    [class~='MuiDataGrid-columnHeader'] {
      background-color: #242424;

      &:last-child {
        [class~='MuiDataGrid-iconSeparator'] {
          display: none;
        }
      }
    }

    [class~='MuiDataGrid-row--editing'] {
      [class~='MuiDataGrid-cell'] {
        background-color: $stroke-color-2 !important;
      }
    }

    [class~='MuiDataGrid-cell'] {
      outline: 0;
    }
  }
}

.custom__sell {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
}