@import '@/shared/styles/mixins.scss';

.rc-toast-presentation {
  display: flex;
  align-items: center;
}
.rc-toast-message {
  font-size: 12px;
  margin-left: 12px;
}

.notify {
  &__title {
    color: #fafafa;
    font-weight: 700;
    font-size: 16px;

    @include media-breakpoint-xxxl {
      font-size: 14px;
    }
  }

  &__message {
    color: #fafafa;
    font-weight: 400;
    font-size: 16px;
    word-break: break-word;

    @include media-breakpoint-xxxl {
      font-size: 14px;
    }
  }

  &__error {
    background: #ff4443 !important;
  }

  &__success {
    background: #24c38e !important;
  }

  &__warning {
    background: #f38b01 !important;
  }

  &__info {
    background: linear-gradient(90deg, #6bbaed 0%, #9fa3e7 40.63%, #c195e3 73.96%, #dd88e0 100%) !important;
  }
}
