@import '@/core/styles/colors.scss';

.search {
  display: flex;
  flex-direction: row;
  padding: 1px;
  border-radius: 30px;

  [class~='MuiInput-input'] {
    color: #ccc;

    &::placeholder {
      color: #fff !important;
    }
  }

  &__field {
    width: 100%;
    border: 1px solid $stroke-color-2;
    border-radius: 30px;
    height: 50px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    padding-left: 32px;
    padding-right: 24px;
    background: rgba(5, 18, 27);
    background-color: $stroke-color-2;

    &_searchIcon {
      margin-top: 0 !important;
      margin-right: 10px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &_loader {
      position: absolute;
      top: 50%;
      right: 44px;
      transform: translateY(-50%);
    }

    &_closeIcon {
      cursor: pointer;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__menu {
    background: rgba(4, 19, 29, 0.7);
    backdrop-filter: blur(20px);
    padding: 30px 20px;
    width: 453px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 20px;

    &__item {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      gap: 15px;
      border-bottom: 1px solid $stroke-color-2;
      padding-bottom: 15px;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      &_column {
        flex-grow: 1;
      }

      &_title {
        font-weight: 500;
        font-size: 16px;
        color: $white;
      }

      &_subtitle {
        font-weight: 500;
        font-size: 11px;
        margin-top: 10px;
        color: $white;

        span {
          background: $main-color-1;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          cursor: pointer;
        }
      }

      &_view {
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        background: $main-color-1;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    &__description {
      font-weight: 500;
      font-size: 11px;
      margin-top: 30px;
      color: white;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: row;
  }

  &__button {
    display: inline-block;
    font-size: 16px;
    padding: 14px 24px;
    height: 52px;
    border-radius: 35px;
    text-transform: none;
    position: relative;
    color: white;
    margin-left: 10px;
    background-size: 300% 100%;
    background-image: linear-gradient(to right, #0165a7, #01cad5, #01cad5, #0165a7);
    box-shadow: 0 2px 15px rgb(0 135 211 / 40%);
    z-index: auto;
    cursor: pointer;
    transition: 0.5s all;

    &:hover {
      background-position: 110% 0%;
    }
  }
}