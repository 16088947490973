@import '@/core/styles/colors';

.mainPage {
  &__title {
    font-size: 32px;
  }

  &__dates {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__subtitle {
    text-align: left;
    font-size: 24px;
    margin-bottom: 0.8em;
  }

  &__list {
    display: inline-block;
    width: 100%;
    text-align: left;
    list-style: none;
    margin-bottom: 20px;
    text-transform: capitalize;

    &__item {
      display: inline-block;
      vertical-align: top;
      padding: 6px 10px;
      padding: 10px 10px 10px;
      margin: 0 20px 20px 0px;
      border: 1px solid $text-color-2;

      font-size: 16px;
      border-radius: 4px;
      text-transform: capitalize;
    }

    &__desc {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 0.4em;
    }

    &__text {
      display: block;
      font-size: 32px;
      text-transform: uppercase;
    }

    &__list {
      margin-bottom: 16px;
    }
  }

  &__sublist {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    padding: 4px 8px;
    margin: 0 0 20px 20px;
    border: none;
  }
}

.noData {
  display: block;
  font-size: 18px;
  padding: 30px 20px;
  margin: 40px auto 80px;
  text-align: center;
  color: tomato;
  background-color: #211313;
  border-radius: 4px;
}
