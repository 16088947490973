@import '@/shared/styles/mixins';

.rich-editor {
  .rich-editor__label {
    display: block;
    margin-left: 2px;
    margin-bottom: 8px;
    color: $text-color-1;
    font-family: $primary-font;
    font-size: 10px;

    @include media-breakpoint-md {
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
    }
  }

  .tox-tinymce {
    border-radius: 4px;
    border-color: $text-color-3;
    background: transparent;

    &:focus-within {
      border-color: $text-color-3;
    }

    .tox-editor-container {
      .tox-editor-header {
        .tox-toolbar-overlord {
          .tox-toolbar__primary {
            .tox-toolbar__group {
              .tox-tbtn--enabled {
                background: none;

                svg {
                  fill: $text-color-3;
                }

                &:hover {
                  background: transparent;
                }
              }

              .tox-tbtn {
                &:hover {
                  background: transparent;
                }

                svg {
                  fill: $text-color-3;
                }

                &__select-label {
                  color: $text-color-3;
                }
              }
            }
          }

          .tox-toolbar__overflow {
            .tox-toolbar__group {
              .tox-tbtn--enabled {
                background: none;

                svg {
                  fill: $text-color-3;
                }

                &:hover {
                  background: transparent;
                }
              }

              .tox-tbtn {
                &:hover {
                  background: transparent;
                }

                svg {
                  fill: $text-color-3;
                }

                &__select-label {
                  color: $text-color-3;
                }
              }
            }
          }
        }

        .tox-tbtn--select {
          width: 92px;
        }
      }
    }

    .tox-statusbar {
      opacity: 0;
    }
  }

  &_focus {
    .tox-tinymce {
      border-color: $text-color-3;
    }
  }
}

.error {
  &__feedback {
    font-size: 12px;
    color: $alert-color;
    display: block;
    margin-top: 6px;
  }
}