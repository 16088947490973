@import '@/shared/styles/mixins';
@import '@/core/styles/colors.scss';

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $color-bg-1;

  &_bg {
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 100%;

    &_primary {
      background-image: url('../../resources/images/common-background.svg');

      @include media-breakpoint-md {
        background-image: url('../../resources/images/common-background-mobile.svg');
      }
    }

    &_secondary {
      background-image: url('../../resources/images/common-background-2.svg');

      @include media-breakpoint-md {
        background-image: url('../../resources/images/common-background-2-mobile.svg');
      }
    }
  }

  &__content {
    flex-grow: 1;
    position: relative;
  }
}
