@import "@/shared/styles/mixins.scss";

.wrapper {
  width: 100%;
  max-width: 1880px;
  margin: 0 auto;
  padding: 0 80px;

  @media (max-width: 1900px) {
    padding-left: 100px;
  }

  @include media-breakpoint-lg {
    padding: 0 16px 0 96px;
    max-width: calc(100vw - 4px);
  }

  @include media-breakpoint-md {
    padding: 0 16px;
  }
}