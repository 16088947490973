@import '@/core/styles/colors.scss';

[class~='MuiPagination-root'] {
  .pagination__list {
    button {
      border-radius: 3px;
      font-size: 16px;
      color: $text-color-1;
      font-family: $primary-font;
      font-weight: 700;
    }
  }
}