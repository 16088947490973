@import '@/core/styles/colors.scss';

.datepicker {
  margin: 0 10px;

  [class~='MuiFormControl-root'] {
    background-color: $color-bg-1;
  }

  [class~='MuiOutlinedInput-input'] {
    padding: 12px 10px;
    color: $white;
    background-color: $color-bg-1;
  }

  [class~='MuiFormLabel-root'] {
    font-size: 11px;
  }

  [class~='MuiSvgIcon-root'] {
    width: 18px;
    height: 18px;
  }
}

.menuPaper {
  max-height: 180px;
}
