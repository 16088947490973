@import "@/core/styles/colors.scss";

.input {
  &__label {
    display: block;
    font-size: 10px;
    margin-left: 2px;
    margin-bottom: 8px;
  }

  &__error {
    position: absolute;
    bottom: -14px;
    right: 0;
    width: 100%;
    max-width: 520px;
    color: tomato;
    font-size: 10px;
  }

  &__icon {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
  }

  input:disabled {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.75);
  }
}
