@import "@/core/styles/colors.scss";

.input {
  position: relative;
  display: block;
  text-align: left;
  max-width: 520px;
  margin: 0 auto 25px;

  &__label {
    display: block;
    font-size: 10px;
    margin-left: 2px;
    margin-bottom: 8px;
  }

  &__error {
    position: absolute;
    bottom: -14px;
    right: 0;
    width: 100%;
    max-width: 520px;
    color: tomato;
    font-size: 10px;
  }

  input {
    padding: 15px 20px;
    color: white;
    background-color: transparent;
    color-scheme: dark;
    &:focus {
      border-color: lighten($stroke-color-2, 30%);
      box-shadow: none;
      outline: none;
      background-color: transparent;
    }
    &::placeholder {
      color: $text-color-3;
      opacity: 0.5;
    }
  }

  input:disabled, textarea:disabled {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.75);
  }
}
