@import '@/shared/styles/mixins';

.gallery {
  margin-top: 50px;

  &__title {
    color: white;
    @include title-32-breakpoints;
  }

  &__grid {
    margin-top: 30px;
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;

    @include media-breakpoint-xl {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__item {
      position: relative;
      border: 1px solid #828082;
      height: 408px;
      border-radius: 10px;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 96%;
        max-height: 100%;
        transform: translate(-50%, -50%);
      }

      &_remove {
        position: absolute;
        top: 5px;
        right: 5px;
        left: 5px;
        background: $color-bg;
        display: none;
        border-radius: 6px;
        z-index: 1;

        &:hover {
          background: $color-bg;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &:hover &_remove {
        display: flex;
      }
    }
  }

  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: #828082;
    cursor: pointer;
    transition: color .4s;

    &:hover {
      color: $white;
    }
  }

  &__add {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-family: $secondary-font;
    font-size: 16px;
    margin-right: 20px;
    color: $text-color-3;
    background-image: $main-color-1;
    border-radius: 5px;


    &_icon {
      display: inline-block;
      vertical-align: top;
      width: 20px;
      height: 20px;
    }
  }
}